<template>
    <div class="listContainer">
        <!-- <div class="dl">
            <div style="margin-right:10px">Proctor Pro</div>
            <img src="../../../assets/dl.png" style="width:100px;" />
        </div> -->
        <div v-for="(item,index) in event_rooms" :key="index" class="eventItem">
            <!-- <img :src="require('../../../assets/banner/'+handleEventName(item.event_name)+'.png')" class="eventImg"/> -->
            <div class="left">
                <div class="itemTitle" :style="{'color':colorMap[handleEventName(item.event_name)]}">{{handleEventName(item.event_name)}}</div>
                <div class="itemName" :style="{'color':colorMap[handleEventName(item.event_name)]}">{{item.name}}</div>
                <div class="eventTime">
                    <div class="eventTimeContainer">
                        <div class="eventTimeItem">Lecture Entry (UTC)：{{formatSessionDate1(item.event_start_time)}}</div>
                        <div class="eventTimeItem">Test Entry (UTC)：{{formatSessionDate1(item.exam_info.event_start_time)}}</div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div v-if="item.name != 'Fundamentals'" class="eventStatusContainer" :style="{'background':colorMap[handleEventName(item.event_name)]}">
                    <div class="toContent"  @click="toRoom(item)">Lecture Entry</div>
                    <!-- <div class="toContent" v-if="item.status.status==='进行中'" @click="toRoom(item)">Lecture Entry</div>
                    <div class="outContent" v-if="item.status.status==='还未开始'" @click="toRoom1(item)">
                        <div class="txt">Lecture Countdown</div>
                        <div class="time" :style="{'color':colorMap[handleEventName(item.event_name)]}">
                            {{item.countDownTimes?item.countDownTimes:getFormatDuringTime(0)}}
                        </div>
                    </div>
                    <div class="outContent" v-if="item.status.status==='已结束'">
                        <div class="txt">Finished</div>
                        <div class="time" :style="{'color':colorMap[handleEventName(item.event_name)]}">
                            {{getFormatDuringTime(0)}}
                        </div>
                    </div> -->
                </div>
                <div class="eventStatusContainer" :style="{'background':colorMap[handleEventName(item.event_name)]}">
                    <div class="toContent" v-if="item.exam_info.status.status==='进行中'" @click="toExam(item)">Test Entry</div>
                    <div class="outContent" v-if="item.exam_info.status.status==='还未开始'">
                        <div class="txt">Test Countdown</div>
                        <div class="time" :style="{'color':colorMap[handleEventName(item.event_name)]}">
                            {{item.exam_info.countDownTimes?item.exam_info.countDownTimes:getFormatDuringTime(0)}}
                        </div>
                    </div>
                    <div class="outContent" v-if="item.exam_info.status.status==='已结束'">
                        <div class="txt">Finished</div>
                        <div class="time" :style="{'color':colorMap[handleEventName(item.event_name)]}">
                            {{getFormatDuringTime(0)}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getMyZhiboRooms,checkTestLinkOpen,checkZhiboOpen,checkZhiboOpenQian,getExerciseTestTemplate ,checkIsUploadTest,checkUserInvigilate} from '../../../api/index'
import { getUserId ,getProjectCode,setTestEvent,setSessionUrl,setSessionName,setIsGroup,setRemoteProjectCode,getProjectLanguage} from '../../../utils/store'
import {formatDate,formatDateUTC} from "../../../utils/date";

export default {
    data(){
        return{
            event_rooms:[],
            colorMap:{
                Artithon:'#11448D',
                Biothon:'#246EB3',
                Chemithon:'#445CB4',
                Econthon:'#E3A948',
                Geothon:'rgb(4,52,124)',
                Historithon:'rgb(171,9,61)',
                Mathethon:'rgb(9,54,80)',
                Physithon:'rgb(53,110,174)',
                Psychothon:'rgb(111,183,217)',
                'Asian-Pacific Economics Cup': '#D9AE48',
                'World Economics Cup': '#D9AE48'

            },
            user_id:getUserId(),
            showChinese:getProjectLanguage(),

        }
    },
    mounted(){
        this.fetchData()
    },
    methods:{
        fetchData(){
            getMyZhiboRooms(getUserId(),'2024WEC_Continental').then(res=>{
                // console.log(res)
                if(res.data.code===0){
                    this.event_rooms = res.data.data.event_rooms.map(item=>{
                        if(item.status.status==='还未开始'){
                            this.handleRestTime(item)
                        }
                        if(item.exam_info.status.status==='还未开始'){
                            this.handleRestTime(item.exam_info)
                        }
                        return item
                    })
                }
            })
        },
        toRoom1(item){


            console.log(11111,item)

            checkZhiboOpenQian(getUserId(),item.event_meeting_room_ids[0],'2024WEC_Continental').then(res=>{
                if(res.data.code===0){

                    if (item.huifang && item.huifang == '是'){
                        console.log(11111,item)


                        setSessionUrl(item.videoUrl)
                        setSessionName(item.name)


                        //判断是否需要监考
                        checkUserInvigilate('2024WEC_Continental',getUserId()).then((res) => {
                            // console.log(1111,res)
                            //true 需要监考
                            if (res.data == true){
                                this.$router.push({
                                    path: '/ready/lecture',
                                    query: {
                                        videoTime:item.videoTime,
                                        template_id:item.template_id
                                    }
                                })
                            }else{

                                let url = this.$router.resolve({
                                    path:'VideoPlay',
                                    query:{
                                        videoTime:item.videoTime
                                    },
                                })
                                window.open(url.href,'_blank')

                            
                            }
                        })

                        // this.$confirm('Are you sure you want to enter the lecture?', 'Tips', {
                        //     confirmButtonText: 'confirm',
                        //     cancelButtonText: 'cancel',
                        //     type: 'warning'
                        // }).then(res=>{
                        //     let url = this.$router.resolve({
                        //         path:'/videoPlay',
                        //         query:{
                        //             videoTime:item.videoTime
                        //         }
                        //     })
                        //     window.open(url.href,'_blank')
                        // })
                    }else{
                        // console.log(222)
                        this.$confirm('Are you sure you want to enter the lecture?', 'Tips', {
                            confirmButtonText: 'confirm',
                            cancelButtonText: 'cancel',
                            type: 'warning'
                        }).then(res=>{
                            let url = this.$router.resolve({
                                path:'/studentscreen',
                                query:{
                                    roomId:item.event_meeting_room_ids[0]
                                }
                            })
                            window.open(url.href,'_blank')
                        })
                    }
                    
                }else if(res.data.code===-1){
                    this.$message.warning('Time not right！')
                }else if(res.data.code===-2){
                    this.$message.warning('No room info')
                }else if(res.data.code===-3){
                    this.$message.warning('Have finished')
                }
            })
        },
        toRoom(item){

            console.log(2222,item)


            setRemoteProjectCode(item.project_code)


            checkZhiboOpen(getUserId(),item.event_meeting_room_ids[0],'2024WEC_Continental').then(res=>{
                if(res.data.code===0){

                    if (item.huifang && item.huifang == '是'){
                        console.log(11111,item)


                        setSessionUrl(item.videoUrl)
                        setSessionName(item.name)
                        

                        console.log(12421412,'2024WEC_Continental',getUserId())


                        //判断是否需要监考
                        checkUserInvigilate('2024WEC_Continental',getUserId()).then((res) => {
                            // console.log(1111,res)
                            //true 需要监考
                            if (res.data == true){
                                this.$router.push({
                                    path: '/ready/lecture',
                                    query: {
                                        videoTime:item.videoTime,
                                        template_id:item.template_id

                                    }
                                })
                            }else{

                                let url = this.$router.resolve({
                                    path:'VideoPlay',
                                    query:{
                                        videoTime:item.videoTime,
                                        template_id:item.template_id
                                    },
                                })
                                window.open(url.href,'_blank')

                            
                            }
                        })

                        // this.$confirm('Are you sure you want to enter the lecture?', 'Tips', {
                        //     confirmButtonText: 'confirm',
                        //     cancelButtonText: 'cancel',
                        //     type: 'warning'
                        // }).then(res=>{
                        //     let url = this.$router.resolve({
                        //         path:'VideoPlay',
                        //         query:{
                        //             videoTime:item.videoTime
                        //         },
                        //     })
                            
                        //     window.open(url.href,'_blank')
                        // })
                    }else{
                        // console.log(222)
                        this.$confirm('Are you sure you want to enter the lecture?', 'Tips', {
                            confirmButtonText: 'confirm',
                            cancelButtonText: 'cancel',
                            type: 'warning'
                        }).then(res=>{
                            let url = this.$router.resolve({
                                path:'/studentscreen',
                                query:{
                                    roomId:item.event_meeting_room_ids[0]
                                }
                            })
                            window.open(url.href,'_blank')
                        })
                    }
                    
                }else if(res.data.code===-1){
                    this.$message.warning('Time not right！')
                }else if(res.data.code===-2){
                    this.$message.warning('No room info')
                }else if(res.data.code===-3){
                    this.$message.warning('Have finished')
                }
            })
        },
        toExam(item){
            console.log(11111,item)
            // checkTestLinkOpen(getUserId(),item.exam_info.name).then(res=>{
            //     if(res.data.code===0){
            //         this.$confirm('Are you sure you want to start the exam?', 'Tips', {
            //             confirmButtonText: 'confirm',
            //             cancelButtonText: 'cancel',
            //             type: 'warning'
            //         }).then(()=>{
            //             let url = this.$router.resolve({
            //                 path:'/test',
            //                 query:{
            //                     template_id:item.exam_info.test_link,
            //                     roomId:res.data.data
            //                 }
            //             })
            //             window.open(url.href,'_blank')
            //         })
            //     }else if(res.data.code===-1){
            //         this.$message.warning('Time not right!')
            //     }else if(res.data.code===-2){
            //         this.$message.warning('No room Info')
            //     }else if(res.data.code===-3){
            //         this.$message.warning('Have Finished')
            //     }else if(res.data.code===-4){
            //         this.$message.warning('Please enter the room in Proctor Pro APP')
            //     }
            // })
            this.goExam(item)
        },
        goExam(item) {


            console.log(1111,item)

            var user_group_id = ''

            setIsGroup('false')

            if (item.event_type == 'group'){
            user_group_id = item.user_group_id

            var app_id = item.app_id
            setAppId(app_id)
            setIsGroup('true')
            }



            var startTime = item.exam_info.event_start_time


            setRemoteProjectCode(item.project_code)
            var temp1 = 0


            console.log(123123,getProjectCode())

            // if (this.showChinese == 'english'){


            var shiqi = this.formatSessionDate1(startTime)

            startTime = new Date(shiqi).getTime()

            var currentTime = this.getUTCTime(0).getTime()

            temp1 = parseInt(startTime) / 1000

            // }else{

            // var currentTime = new Date().getTime()

            // temp1 = parseInt(startTime)
            // }

            console.log(123111,temp1)
            //TODO:为了测试，去掉时间判断
            var test_event = item.name
            if (item.exam_info.type == 'Formal'){
            if (temp1 - this.exam_entry_before_time > currentTime) {
                this.$message({
                message:
                    "The test has not started. You can only enter 15 minutes before the start time",
                type: "warning",
                });
                return;
            }
            //考试开始15分钟，无法进入考场
            if (temp1 + this.exam_prohibit_time_when_start < currentTime) {
                this.$message({
                message:
                    "The entry time is already over. You can not enter into the test room now.",
                type: "warning",
                });
                return;
            }
            }



            getExerciseTestTemplate(item.name, getProjectCode(),item.exam_info.type,test_event).then((res) => {
            if (res.data.code == 0){
                var result = res.data.data
                var template_id = result._id.$id
                this.doCheck(template_id,temp1,item.exam_info.type,test_event,user_group_id)
            }else{
                this.$message({
                message: res.data.msg,
                type: 'warning'
                })
            }
            
            })
            },
        doCheck(template_id,timeStr,event_type,test_event,user_group_id) {
        var student_id = getUserId()
        if (user_group_id != ''){
        student_id = user_group_id;
        }
        checkIsUploadTest(template_id, student_id).then((res) => {
        var result = res.data
        // result= false
        if (result) {
            this.$alert(this.$t('m.wanchengle'), 'Tip', {
            confirmButtonText: 'OK',
            cancelButtonText:'Cancel',
            callback: () => {
            }
            });
        } else {
            setTestEvent(test_event)


            if (this.showChinese == 'english'){

            checkUserInvigilate(getProjectCode(),getUserId()).then((res) => {
                console.log(1111,res)
                //true 需要监考
                if (res.data == true){
                this.$router.push({
                    path: '/ready/exam',
                    query: {
                    template_id: template_id,
                    start_time:timeStr,
                    event_type:event_type
                    }
                })
                }else{

                this.$router.push({
                    path: "/exam",
                    query: {
                    template_id: template_id,
                    invigilateStatus:true
                    },
                });
                }
            })
            
            }else{
                this.checkTip(template_id,timeStr,event_type)
            }

            
        }
        })
        },

        checkTip(template_id,timeStr,event_type){

        var info = '请注意：考生在进入考试前需确认下线所有社交软件，并关闭翻译软件及自动取词翻译功能。若在考试过程中，系统监考屏幕录制到使用社交软件聊天，使用翻译软件或翻译器取词翻译的情况，经监考员核实后将直接取消考生成绩并通报学校。'

        this.$confirm(info, '', {
            confirmButtonText: '我已知悉并确认关闭所有社交及翻译软件',
            // cancelButtonText: '取消',
            showCancelButton: false
            // type: 'warning'
        }).then(() => {
            //判断是否需要监考

            checkExamInfo(getProjectCode(),getUserId(),template_id,info).then((res) => {
            })

            checkUserInvigilate(getProjectCode(),getUserId()).then((res) => {
            console.log(1111,res)
            //true 需要监考
            if (res.data == true){
                this.$router.push({
                path: '/ready/exam',
                query: {
                    template_id: template_id,
                    start_time:timeStr,
                    event_type:event_type
                }
                })
            }else{

                this.$router.push({
                path: "/exam",
                query: {
                    template_id: template_id,
                    invigilateStatus:true
                },
                });
            }
            })

        }).catch(() => {
                
        });
        },
        formatSessionDate(time) {
            var time_1 = JSON.parse(time) * 1000;
            var date = new Date(time_1)
            return formatDate(date, 'yyyy-MM-dd hh:mm')
        },
        formatSessionDate1(time) {
            var time_1 = JSON.parse(time) * 1000;
            var date = new Date(time_1)
            return formatDateUTC(date, 'yyyy-MM-dd hh:mm')
        },
        getUTCTime(data){
            var time=new Date(); //获取时间
            // 获取时间偏移量 getTimezoneOffset 获取格林威治时间   *60000是到毫秒
            var dataOffset=new Date().getTimezoneOffset()*60000
            // 获取格林威治时间 
            var utc=time.getTime()+dataOffset;  // 两个时间戳
            // 拿格林威治时间去反推指定地区时间    
            var newTime=utc+3600000*data;  // 
            var times=new Date(newTime);
            return times
        },	
        formateTime(time){
            let item = new Date(time*1000);
            return `${item.getMonth()+1}-${item.getDate()} ${item.getHours()}:${item.getMinutes()>9?item.getMinutes():'0'+item.getMinutes()}`;
        },
        handleEventName(event){

            return event
            let event_array = event.split(" ")
            return event_array[0]
        },
        handleIndex(index){
            return index%4+1;
        },
        getFormatDuringTime(during) {
            var s = Math.floor(during / 1) % 60;
            during = Math.floor(during / 60);
            var i = during % 60;
            during = Math.floor(during / 60);
            var h = during;
            return  h + ':' + i + ':' + s ;
        },
        handleRestTime(item){
            let totalTime = item.status.rest_time
            item.countDownThread = window.setInterval(() => {
                this.$set(item,'countDownTimes',this.getFormatDuringTime(totalTime))
                totalTime--
                if (totalTime < 0) {
                    window.clearInterval(item.countDownThread)
                    item.status.status='进行中'
                }
                // console.log(this.event_rooms)
            }, 1000)
        }
    }
}
</script>

<style lang="less">
.listContainer{
    .dl{
        width: 84%;
        display: flex;
        align-items: center;
        font-family: Impact;
        justify-content: center;
 
        color: #072D76;
        font-size: 50px;
        margin-bottom: 20px;
    }
    .eventItem{
        width: 84%;
        height: 17vh;
        margin-bottom: 4vh;
        position: relative;
        background-color: white;
        display: flex;
        margin-left: 20px;
        margin-top: 20px;
        .eventImg{
            position: absolute;
            top:0;
            // z-index: 1;
            height: 100%;
            width: 100%;
            max-width: 100%;
            display: block;
        }
        .left{
            width: 60%;
            overflow: hidden;
            position: relative;
            .itemTitle{
                margin-top:2vh;
                font-size: 2.4vh;
                font-family: Impact;
                font-weight: 400;
                padding-left:30px;
                white-space:nowrap;
            }
            .itemName{
                font-size: 5vh;
                font-family: Impact;
                font-weight: 400;
                padding-left:30px;
                white-space:nowrap;
            }
            .eventTime{
                background: #32B67A;
                height: 2.6vh;
                width: 100%;
                margin-top:1vh;
                margin-left: 30px;
                color: white;
                font-size: 1.4vh;
                line-height: 2.6vh;
                font-family: Microsoft YaHei;
                font-weight: bold;
                .eventTimeContainer{
                    width: 90%;
                    margin:0 auto;
                    display: flex;
                    .eventTimeItem{
                        width: 50%;
                        text-align: center;
                    }
                }
            }
        }
        .right{
            width: 38%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .eventStatusContainer{
                width: 18vh;
                height: 50%;
                margin-left: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .toContent{
                    text-align: center;
                    color: white;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    cursor: pointer;
                    padding: 3vh;
                }
                .outContent{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-around;
                    .txt{
                        margin-top: 0.4vh;
                        font-size: 1.4vh;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #FFFFFF;
                        text-align: center;
                    }
                    .time{
                        background: white;
                        width: 80%;
                        padding: 0.6vh 4px;
                        font-family: Impact;
                        font-weight: 400;
                        font-size: 3vh;
                        text-align: center;
                        margin-bottom: 0.4vh;
                    }
                }
            }
        }
    }
}
</style>